

























































































































































import mixins from 'vue-typed-mixins'
import DraftActions from '@/builder/mixins/DraftActions'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import { DraftConfigurationType } from '@/calendesk/models/BuilderTypes'
import PlanActions from '@/builder/mixins/PlanActions'
import { DraftConfiguration } from '@/calendesk/models/DraftConfiguration'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import DefaultLanguages from '@/calendesk/models/DefaultLanguages'
import { WebsiteLanguageType } from '@/calendesk/models/WebsiteLanguageType'
import CalendeskInformationMessage from '@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue'
import getLanguageName from '@/calendesk/tools/getLanguageName'

export default mixins(DraftActions, PlanActions).extend({
  name: 'General',
  components: { CalendeskInformationMessage },
  data () {
    return {
      googleTagIdEnabled: false,
      tools: [
        DraftConfigurationType.SELECT,
        DraftConfigurationType.TEXT,
        DraftConfigurationType.LONG_TEXT,
        DraftConfigurationType.CHECKBOX
      ],
      acceptedVariables: [
        'wb_website_description__long_text__',
        'wb_favicon_url__text__',
        'wb_hide_login__checkbox__',
        'wb_hide_signup__checkbox__',
        'wb_hide_header__checkbox__',
        'wb_hide_footer__checkbox__',
        'wb_hide_booking_employees__checkbox__',
        'wb_enable_discounts__checkbox__',
        'wb_hide_time_zone__checkbox__',
        'wb_primary_language__select__',
        'wb_hide_booking_time_to__checkbox__'
      ],
      mode: DraftActionsType.CONFIGURATION,
      draftConfigurationType: DraftConfigurationType
    }
  },
  created () {
    this.googleTagIdEnabled = this.googleTagAvailable
  },
  methods: {
    getItemsForSelectType (type: BuilderSelectType): Array<Record<string, number | string>> {
      switch (type) {
        case BuilderSelectType.LANGUAGE:
          return DefaultLanguages()
      }

      return []
    },
    getTitleForSelectType (type: BuilderSelectType): string | null {
      switch (type) {
        case BuilderSelectType.LANGUAGE:
          return this.$trans('c_language_select_label')
      }

      return null
    },
    getObjectForSelectTypeAndValue (type: BuilderSelectType, value: WebsiteLanguageType | string): Record<string, any> | string | null {
      switch (type) {
        case BuilderSelectType.LANGUAGE:
          return {
            text: getLanguageName(value as WebsiteLanguageType),
            value
          }
      }

      return null
    },
    updateCheckboxValue (config: DraftConfiguration) {
      const protectedSections = [
        'wb_hide_header__checkbox__',
        'wb_hide_footer__checkbox__',
        'wb_hide_time_zone__checkbox__'
      ]

      if (protectedSections.includes(config.key) && !this.hideSectionsAvailable) {
        this.openFeatureNotAvailableDialog()
      } else {
        this.updateConfigurationProperty(config.key, config.value, true)
      }
    },
    updateGeneralConfigurationPropertyForSelect (key: string, value: any, selected: any) {
      value.value = selected.value
      this.$store.dispatch('setup/updateLanguage', value.value, { root: true }).then(() => {
        this.updateConfigurationPropertyForSelect(key, value, selected)
      })
    },
    updateCustomCode (value: string) {
      this.updateConfigurationProperty('wb_custom_code__long_text__', value)
    }
  }
})
