import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { WebsiteLanguageType } from '@/calendesk/models/WebsiteLanguageType'

export default () => [
  {
    text: trans('c_language_select_czech'),
    value: WebsiteLanguageType.CS
  },
  {
    text: trans('c_language_select_german'),
    value: WebsiteLanguageType.DE
  },
  {
    text: trans('c_language_select_english'),
    value: WebsiteLanguageType.EN
  },
  {
    text: trans('c_language_select_spanish'),
    value: WebsiteLanguageType.ES
  },
  {
    text: trans('c_language_select_french'),
    value: WebsiteLanguageType.FR
  },
  {
    text: trans('c_language_select_hindi'),
    value: WebsiteLanguageType.HI
  },
  {
    text: trans('c_language_select_dutch'),
    value: WebsiteLanguageType.NL
  },
  {
    text: trans('c_language_select_polish'),
    value: WebsiteLanguageType.PL
  },
  {
    text: trans('c_language_select_portuguese'),
    value: WebsiteLanguageType.PT
  },
  {
    text: trans('c_language_select_russian'),
    value: WebsiteLanguageType.RU
  },
  {
    text: trans('c_language_select_slovak'),
    value: WebsiteLanguageType.SK
  },
  {
    text: trans('c_language_select_ukrainian'),
    value: WebsiteLanguageType.UK
  },
  {
    text: trans('c_language_select_bulgarian'),
    value: WebsiteLanguageType.BG
  },
  {
    text: trans('c_language_select_greek'),
    value: WebsiteLanguageType.EL
  },
  {
    text: trans('c_language_select_estonian'),
    value: WebsiteLanguageType.ET
  },
  {
    text: trans('c_language_select_finnish'),
    value: WebsiteLanguageType.FI
  },
  {
    text: trans('c_language_select_irish'),
    value: WebsiteLanguageType.GA
  },
  {
    text: trans('c_language_select_croatian'),
    value: WebsiteLanguageType.HR
  },
  {
    text: trans('c_language_select_hungarian'),
    value: WebsiteLanguageType.HU
  },
  {
    text: trans('c_language_select_lithuanian'),
    value: WebsiteLanguageType.LT
  },
  {
    text: trans('c_language_select_latvian'),
    value: WebsiteLanguageType.LV
  },
  {
    text: trans('c_language_select_maltese'),
    value: WebsiteLanguageType.MT
  },
  {
    text: trans('c_language_select_slovenian'),
    value: WebsiteLanguageType.SL
  },
  {
    text: trans('c_language_select_albanian'),
    value: WebsiteLanguageType.SQ
  },
  {
    text: trans('c_language_select_swedish'),
    value: WebsiteLanguageType.SV
  }
]
